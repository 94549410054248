<template>
  <div class="processing-capacity">
    <div class="sel-box">
      <!-- <Select style="width: 200px" v-model="farmId">
        <Option v-for="(item, index) in farms" :key="index" :value="item.id">{{
          item.name
        }}</Option>
      </Select> -->
      <Input
        v-model="farmName"
        placeholder="请选择养殖场"
        style="width: 200px"
        @on-focus="selFarm"
      ></Input>
      <DatePicker
        type="daterange"
        style="width: 200px; margin-left: 15px"
        v-model="firstDate"
      ></DatePicker>
    </div>
    <div class="deal-with-capcity">
      <div class="d-w-c-data-box">
        <div class="d-w-c-data-item">
          <i-circle
            :percent="20"
            :stroke-color="['#BAFF7D', '#23A6F2']"
            :trail-width="0"
          >
            <div class="img-box">
              <img src="imgs/produce/c.png" alt="" />
            </div>
          </i-circle>
          <p class="num">{{ topData.manureAll }}m³</p>
          <p>粪污总产量</p>
        </div>
        <div class="d-w-c-data-item">
          <i-circle
            :percent="20"
            :stroke-color="['#BAFF7D', '#23A6F2']"
            :trail-width="0"
          >
            <div class="img-box">
              <img src="imgs/produce/a.png" alt="" />
            </div>
          </i-circle>
          <p class="num">{{ topData.poolAll }}m³</p>
          <p>集粪池现容量</p>
        </div>
        <div class="d-w-c-data-item">
          <i-circle
            :percent="20"
            :stroke-color="['#BAFF7D', '#23A6F2']"
            :trail-width="0"
          >
            <div class="img-box">
              <img src="imgs/produce/b.png" alt="" />
            </div>
          </i-circle>
          <p class="num">{{ topData.consumptiveAll }}m³</p>
          <p>总粪污消纳量</p>
        </div>
        <div class="d-w-c-data-item">
          <div class="m-d-method">
            <img src="imgs/produce/e.png" alt="" />
            <span>转运</span>
            <span style="font-size: 20px; color: #cd54f9"
              >{{ topData.transportAll }}m³</span
            >
          </div>
          <div class="m-d-method">
            <img src="imgs/produce/f.png" alt="" />
            <span>自用</span>
            <span style="font-size: 20px; color: #54b1f9"
              >{{ topData.selfAll }}m³</span
            >
          </div>
          <div class="m-d-method">
            <img src="imgs/produce/d.png" alt="" />
            <span>施用</span>
            <span style="font-size: 20px; color: #2bbac4"
              >{{ topData.applyAll }}m³</span
            >
          </div>
        </div>
      </div>
      <div class="processing-formula">
        <div class="p-c">
          <!-- <span>处理能力</span>
          <span class="percent">100%</span> -->
        </div>
        <div class="f-box">
          <span>总排污量</span>
          <span class="symbol">≈</span>
          <span>集粪池现容量</span>
          <span class="symbol">+</span>
          <span>总粪污消纳量</span>
        </div>
        <div class="f-box">
          <span>总粪污消纳量</span>
          <span class="symbol">≈</span>
          <span>转运</span>
          <span class="symbol">+</span>
          <span>自用</span>
          <span class="symbol">+</span>
          <span>施用</span>
        </div>
      </div>
    </div>
    <div class="chart-box">
      <div class="head-box">
        <p class="title">
          <!-- <span
            :class="[tableType == 1 ? 'active' : '']"
            @click="() => tableTypeCut(1)"
            >图表</span
          >
          <span
            :class="[tableType == 2 ? 'active' : '']"
            @click="() => tableTypeCut(2)"
            >列表</span
          > -->
        </p>
        <div class="filter-box">
          <span
            :class="[
              'filter-item',
              monitorType == 'day' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('day')"
            >日</span
          >
          <!-- <span
            :class="[
              'filter-item',
              monitorType == 'week' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('week')"
            >本周</span
          > -->
          <span
            :class="[
              'filter-item',
              monitorType == 'month' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('month')"
            >月</span
          >
          <span
            :class="[
              'filter-item',
              monitorType == 'year' ? 'filter-item-active' : '',
            ]"
            @click="() => monitorCut('year')"
            >年</span
          >
          <DatePicker
            type="daterange"
            style="width: 200px"
            v-model="monitorDate"
            placement="left-start"
          ></DatePicker>
        </div>
      </div>
      <div class="chart" ref="chart"></div>
    </div>

    <select-farm
      v-model="selModal"
      :farmId="farmId"
      :options="options"
      isDefault
      @sel-finish="selFinish"
    ></select-farm>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import selectFarm from "@/components/selectFarm";
export default {
  name: "",
  components: {
    selectFarm,
  },
  data() {
    return {
      monitorDate: [ moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),],
      monitorType: "day",

      tableType: 1,
      chart: null,
      farms: [],
      farmId: "",

      firstDate: [
        moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
      ],
      topData: {},
      bottomData: {},
      farmName: "",
      selModal: false,
      options: {
        siteType: "2",
      },
    };
  },
  watch: {
    farmId(n) {
      if (!n) return
      this.getTopData();
      this.getBottomData();
    },
    firstDate() {
      if (!this.farmId) return
      this.getTopData();
    },
    monitorDate() {
      if (!this.farmId) return
      this.getBottomData();
    },
  },
  methods: {
    selFarm() {
      this.selModal = true;
    },
    selFinish(farm) {
      this.farmId = farm.id;
      this.farmName = farm.name;
      this.selModal = false;
    },
    getFarms() {
      this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST, {
        siteType: "2",
      }).then((res) => {
        if (res.list.length == 0) return;
        this.farms = res.list;
        this.farmId = res.list[0].id;
      });
    },
    monitorCut(type) {
      if (this.monitorType == type) return;
      this.monitorType = type;
      if (!this.farmId) return;
      if (this.monitorType == "day") {
        this.monitorDate=[]
        this.monitorDate.push(
          moment(new Date())
            .subtract(7, "days")
            .format("YYYY-MM-DD")
        );
        this.monitorDate.push(moment().format("YYYY-MM-DD"));
        console.log(this.monitorDate);
      }

      if (this.monitorType == "month") {
        this.monitorDate=[]
        this.monitorDate.push(
          moment(new Date())
            .subtract(60, "days")
            .format("YYYY-MM-DD")
        );
        this.monitorDate.push(moment().format("YYYY-MM-DD"));
        this.$forceUpdate()
        console.log(this.monitorDate);
      }
      if (this.monitorType == "year") {
        this.monitorDate=[]
        this.monitorDate.push(
          moment(new Date())
            .subtract(2, "year")
            .format("YYYY-MM-DD")
        );
        this.monitorDate.push(moment().format("YYYY-MM-DD"));
        console.log(this.monitorDate);
      }
     
      this.getBottomData();
    },

    tableTypeCut(type) {
      if (this.tableType == type) return;
      this.tableType = type;
    },

    initChart() {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart);
      }
      let xData = Object.keys(this.bottomData.applyTimeLine).sort(
        (a, b) => a - b
      );
      let transportData = xData.map(
        (item) => this.bottomData.transportTimeLine[item]
      );
      let selfData = xData.map((item) => this.bottomData.selfTimeLine[item]);
      let applyData = xData.map((item) => this.bottomData.applyTimeLine[item]);
      let option = {
        grid: {
          top: 30,
          left: 50,
          right: 60,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["转运", "自用", "施用"],
        },
        xAxis: {
          name: "时间段",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
        },
        yAxis: [
          {
            name: "单位(m³)",
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "#f6f6f6",
              },
            },
          },
        ],
        color: ["#CD54F9", "#54B1F9", "#2BBAC4"],
        series: [
          {
            type: "line",
            smooth: true,
            name: "转运",
            data: transportData,
          },
          {
            type: "line",
            smooth: true,
            name: "自用",
            data: selfData,
          },
          {
            type: "line",
            smooth: true,
            name: "施用",
            data: applyData,
          },
        ],
      };
      this.chart.setOption(option);
    },
    resize() {
      this.chart.resize();
    },

    getTopData() {
      this.$post(this.$api.TOTAL_TABE.PROCESSING_CAPCITY, {
        farmId: this.farmId,
        startTime: !this.firstDate[0]
          ? ""
          : moment(this.firstDate[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: !this.firstDate[1]
          ? ""
          : moment(this.firstDate[1]).format("YYYY-MM-DD HH:mm:ss"),
      }).then((res) => {
        this.topData = res;
      });
    },
    getBottomData() {
      this.$post(this.$api.TOTAL_TABE.PROCESSING_CAPCITY_CHAT, {
        farmId: this.farmId,
        startTime: !this.monitorDate[0]
          ? ""
          : moment(this.monitorDate[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: !this.monitorDate[1]
          ? ""
          : moment(this.monitorDate[1]).format("YYYY-MM-DD HH:mm:ss"),
        type: this.monitorType,
      }).then((res) => {
        this.bottomData = res;
        this.initChart();
      });
    },
  },
  mounted() {
    // this.getFarms();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>